@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
/* ibm-plex-mono-regular - latin */
@font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/ibm-plex-mono-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/ibm-plex-mono-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/ibm-plex-mono-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/ibm-plex-mono-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/ibm-plex-mono-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/ibm-plex-mono-v12-latin-regular.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
  }
  /* ibm-plex-mono-600 - latin */
  @font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/ibm-plex-mono-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/ibm-plex-mono-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/ibm-plex-mono-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/ibm-plex-mono-v12-latin-600.woff') format('woff'), /* Modern Browsers */
         url('./fonts/ibm-plex-mono-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/ibm-plex-mono-v12-latin-600.svg#IBMPlexMono') format('svg'); /* Legacy iOS */
  }
    html {
      font-family: "IBM Plex Mono", monospace;
    }
  }
